import React from 'react'
import styled from 'styled-components'

import rem from '../utils/rem'
import { navbarHeight} from '../utils/sizes'
import { main } from '../utils/colours'
import Layout from '../components/Layout'
import { Content } from '../components/Scaffold'

const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - ${rem(navbarHeight)});
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ContentWrapper = styled(Content)`
  text-align: center;
  align-self: center;
  margin-top: ${rem(-240)};
`

export default () => (
  <Layout>
    <Wrapper>
      <ContentWrapper>
        <h1 style={{color: main}}>404.</h1>
        <p>This page can't be found. Double check your URL.</p>
      </ContentWrapper>
    </Wrapper>
  </Layout>
)
